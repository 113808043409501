export const skillsThecnical = [
  {
    skill: 'HTML',
    level: 4.6,
  },
  {
    skill: 'CSS',
    level: 4.2,
  },
  {
    skill: 'Javascript',
    level: 4.8,
  },
  {
    skill: 'PHP',
    level: 4,
  },
  {
    skill: 'C',
    level: 4,
  },
  {
    skill: 'JAVA',
    level: 4,
  },
  {
    skill: 'Python',
    level: 3,
  },
  {
    skill: 'Drat',
    level: 3.5,
  },
  {
    skill: '.Net',
    level: 3,
  },
  {
    skill: 'Sequelize',
    level: 4,
  },
  {
    skill: 'GraphQL',
    level: 4.2,
  },
  {
    skill: 'MongoDB (DB)',
    level: 3.5,
  },
  {
    skill: 'Postgresql (DB)',
    level: 4.8,
  },
  {
    skill: 'Access (DB)',
    level: 4.5,
  },
  {
    skill: 'SQlite (DB)',
    level: 4.8,
  },
  {
    skill: 'ANSI SQL',
    level: 4.5,
  },
  {
    skill: 'Scriptcase (PHP)',
    level: 4,
  },
  {
    skill: 'Photoshop',
    level: 4.4,
  },
  {
    skill: 'DynamoDB (AWS)',
    level: 3.5,
  },
  {
    skill: 'Pinpoint (AWS)',
    level: 3.5,
  },
  {
    skill: 'S3 (AWS)',
    level: 4,
  },
  {
    skill: 'Cognito (AWS)',
    level: 4,
  },
  {
    skill: 'Amplify (AWS)',
    level: 4,
  },
  {
    skill: 'Spring Boot (JAVA)',
    level: 4,
  },
  {
    skill: 'RX (JS, JAVA)',
    level: 4,
  },
  {
    skill: 'jQuery (JS)',
    level: 4,
  },
  {
    skill: 'React (JS)',
    level: 4.8,
  },
  {
    skill: 'Redux (JS)',
    level: 4.6,
  },
  {
    skill: 'React Native (JS)',
    level: 4.8,
  },
  {
    skill: 'NodeJS (JS)',
    level: 4.6,
  },

  {
    skill: 'NPM',
    level: 4.8,
  },
  {
    skill: 'GIT',
    level: 4.5,
  },
];
