import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Card, Typography, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useWords from '../../../../utils/Words';
import Colors from '../../../../utils/Colors';
const profileImagen = require('../../../../utils/assets/johannavarro.png');

const StartPresentation = () => {
  const classes = useStyles();
  const words = useWords();

  return (
    <Row
      className={classes.row1}
      center={'lg'}
      middle={'lg'}
      id={'1Presentation'}
    >
      <Col lg={6} xs={12}>
        <Card className={classes.cardPresentation}>
          <CardContent>
            <Typography variant="h3" component="h2" className={classes.title}>
              Johan Navarro Cano
            </Typography>
            <hr className={classes.divider} />
            <Typography
              variant="h4"
              component="h2"
              className={classes.title}
              gutterBottom={true}
            >
              {words.presentation.title}
            </Typography>
            <br />
            <Typography
              variant="body1"
              component="p"
              align="center"
              className={classes.paragraf}
            >
              {words.presentation.p1}
            </Typography>
            <br />
            <Typography
              variant="body1"
              component="p"
              align="center"
              className={classes.paragraf}
            >
              {words.presentation.p2}
            </Typography>
          </CardContent>
        </Card>
      </Col>
      <Col lg={6} xs={12}>
        <div className={classes.cardProfileImage}>
          <img
            src={profileImagen}
            alt="profile_image"
            className={classes.profileImage}
          />
        </div>
      </Col>
    </Row>
  );
};
const useStyles = makeStyles(() => ({
  cardPresentation: {
    backgroundColor: Colors.balckTwo,
    color: '#fff',
  },
  cardProfileImage: {
    color: '#fff',
    textAlign: 'center',
    alignItems: 'center',
    minHeight: '100%',
  },
  profileImage: {
    borderRadius: '50%',
    webkitBoxShadow: 'none',
    boxShadow: 'none',
    overflow: 'hidden',
    width: '60%',
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Oranienbaum',
  },
  row1: {
    backgroundColor: Colors.balckOne,
    minHeight: '100vh',
  },
  divider: {
    border: 0,
    height: '3px',
    backgroundImage:
      'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(140, 140, 140, 0.7), rgba(0, 0, 0, 0))',
  },
  paragraf: {
    fontFamily: 'Open Sans',
    fontSize: '18px',
  },
  root: {
    maxWidth: 345,
  },
}));
export default StartPresentation;
