import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../utils/Colors';
export default makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  buttonGoldenColor: {
    color: '#fff',
    background: `linear-gradient(45deg, rgb(169,141,58) 30%,  #F4DE6E 90%) `,
    boxShadow: `0 3px 5px 2px ${Colors.goldenThreeD}`,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  sendButton: {
    marginTop: 5,
    color: '#fff',
    background: `linear-gradient(45deg, rgb(169,141,58) 30%,  #F4DE6E 90%) `,
    boxShadow: `0 3px 5px 2px ${Colors.goldenThreeD}`,
  },
  whatsappIcon: {
    color: '#fff',
    fontSize: 35,
  },
  whatsappFab: {
    background:
      'linear-gradient(45deg, rgb(0,253,105) 30%, rgb(0,188,164) 90%)',
    boxShadow: '0 3px 5px 2px rgba(0,253,105, .3)',
  },
  //Fab Menu
  rootFlabMenu: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
    marginBottom: '-51.5vh',
    marginLeft: '65vw',
  },
  exampleWrapper: {
    position: 'relative',
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  //Index.js
  title: {
    textAlign: 'center',
    fontFamily: 'Oranienbaum',
  },
  divider: {
    border: 0,
    height: '3px',
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0),${Colors.grayOne}, rgba(0, 0, 0, 0))`,
  },
  divider2: {
    border: 0,
    height: '1px',
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0), ${Colors.grayOne}, rgba(0, 0, 0, 0))`,
  },
  row1: {
    paddingTop: 30,
    backgroundColor: 'rgb(9,58,71)',
  },
  row2: {
    backgroundColor: 'rgb(9,58,71)',
    minHeight: '100vh',
  },
  cardCompany: {
    textAlign: 'center',
    backgroundColor: '#ffff',
    color: '#ffff',
    padding: '4%',
    marginTop: '3%',
    backgroundImage: `linear-gradient( to right,rgb(9,58,71), rgb(16,128,149),rgb(9,58,71))`,
    marginBottom: '2%',
    minHeight: '40vh',
  },
  imageCompany: {
    height: '80px',
    margin: '1%',
    textAlign: 'center',
  },
  paragraf: {
    fontFamily: 'Open Sans',
    fontSize: '18px',
  },
  //App Header
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: Colors.balckOne,
  },
  toolbar: {
    flexWrap: 'wrap',
    color: '#fff',
  },
  toolbarTitle: {
    flexGrow: 1,
    fontFamily: 'Oranienbaum',
    fontSize: '40px',
    cursor: 'pointer',
  },
  link: {
    margin: theme.spacing(1, 1.5),
    color: '#fff',
    background: `linear-gradient(45deg, rgb(169,141,58) 30%,  #F4DE6E 90%) `,
    boxShadow: `0 3px 5px 2px ${Colors.goldenThreeD}`,
  },
  //form
}));
