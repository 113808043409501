import React, { useState } from 'react';
import _ from 'lodash';
import { Col, Row } from 'react-flexbox-grid';
import { Card, Typography } from '@material-ui/core';

import { Subject, DonutSmall } from '@material-ui/icons';
import Classes from '../../Sytiles';
import Fab from '@material-ui/core/Fab';
import Title from '../Title/Title.jsx';
/* Utilities */
import useWords from '../../../../utils/Words';
import charts from '../Charts/dataPersonal.js';
const iasImagen = require('../../../../utils/assets/IAS.png');

const CardsText = () => {
  const words = useWords();
  const classes = Classes();
  return _.map(words.ias, (topic, key) => {
    const { icon, title, paragraphs } = topic;
    return (
      <Col lg={4} md={6} key={key + 'CompanyText'}>
        <Card className={classes.cardCompany}>
          <Typography variant="h5" component="h5" className={classes.title}>
            {icon}
          </Typography>
          <Typography variant="h5" component="h5" className={classes.title}>
            {title}
          </Typography>
          <hr className={classes.divider} />
          {paragraphs.map((paragraph, key) => {
            return (
              <div key={key + 'Text'}>
                <Typography
                  variant="body1"
                  component="p"
                  align="center"
                  className={classes.paragraf}
                >
                  {paragraph}
                </Typography>
                <hr className={classes.divider2} />
              </div>
            );
          })}
        </Card>
      </Col>
    );
  });
};
const CardsCharts = () => {
  const words = useWords();
  const classes = Classes();
  return charts({ words }).ias.map((topic, key) => {
    const { icon, title, chart } = topic;
    return (
      <Col lg={4} key={key} md={6}>
        <Card className={classes.cardCompany}>
          <Typography variant="h5" component="h5" className={classes.title}>
            {icon}
          </Typography>
          <Typography variant="h5" component="h5" className={classes.title}>
            {title}
          </Typography>
          <hr className={classes.divider} />
          {chart}
        </Card>
      </Col>
    );
  });
};
const CardCompany = () => {
  const words = useWords();
  const classes = Classes();
  const [showText, setShowText] = useState(true);
  return (
    <>
      <Row className={classes.row1} middle={'xs'} end={'xs'} id={'3Companies'}>
        <Col lg={12}>
          <Title text={words.lastCompany} />
        </Col>
        <Col lg={8} xsOffset={12}>
          <div className={classes.imageCompany}>
            <img src={iasImagen} alt="ias_image" />
          </div>
        </Col>
        <Col lg={2} xs={7} style={{ textAlign: 'center' }}>
          <Fab
            label="Primary"
            color="secondary"
            onClick={() => {
              setShowText(!showText);
            }}
            className={classes.buttonGoldenColor}
          >
            {showText ? <DonutSmall /> : <Subject />}
          </Fab>
        </Col>
      </Row>
      <Row className={classes.row2} top={'lg'}>
        {showText && <CardsText />}
        {!showText && <CardsCharts />}
      </Row>
    </>
  );
};

export default CardCompany;
