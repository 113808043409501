import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContactMeForm from './ContactMeForm';
import CardText from '../CardText/CardText';
import Colors from '../../../../utils/Colors';
import useWords from '../../../../utils/Words';

const ContactMe = () => {
  const classes = useStyles();
  const words = useWords();
  return (
    <Grid fluid={true} className={classes.grid}>
      <Row className={classes.row1} center={'xs'} top={'lg'}>
        <Col lg={6} xs={12} className={classes.contentForm}>
          <Card className={classes.cardPresentation}>
            <CardContent>
              <CardText
                subTitle={words.contactMe.thanks}
                paragraphs={words.letter}
              />
            </CardContent>
          </Card>
        </Col>
        <Col lg={6} xs={12} className={classes.contentForm}>
          <Card className={classes.cardPresentation}>
            <CardContent>
              <ContactMeForm />
            </CardContent>
          </Card>
        </Col>
      </Row>
    </Grid>
  );
};
const useStyles = makeStyles(() => ({
  grid: {
    padding: '0.599%',
  },
  cardPresentation: {
    backgroundColor: Colors.balckTwo,
    color: '#fff',
  },
  contentForm: {
    marginTop: '10%',
  },
  row1: {
    backgroundColor: Colors.balckOne,
    minHeight: '97vh',
  },
}));
export default ContactMe;
