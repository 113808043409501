import React from 'react';
import { Grid } from 'react-flexbox-grid';
import { makeStyles } from '@material-ui/core/styles';
import StartPresentation from './StartPresentation/StartPresentation';
import Companies from './Companies/Companies';
import CardCompany from './CardCompany';
import SkillsSpider from './SkillsSpider/SkillsSpider';
import Products from './Products/Product';
import Education from './Education/Education';

export const Presentation = () => {
  const classes = useStyles();
  return (
    <Grid fluid={true} className={classes.grid}>
      <StartPresentation />
      <Education />
      <SkillsSpider />
      <CardCompany />
      <Companies />
      <Products />
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  grid: {
    padding: '0.599%',
  },
}));

export default Presentation;
