import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Image from 'material-ui-image';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    marginBottom: 20,
  },
  media: {
    height: 140,
  },
});

const ProductCard = (props) => {
  const classes = useStyles();
  const {
    image,
    title,
    subtitle,
    text,
    setShowModalVideo,
    position,
    titleProduct,
    textButtonAction,
    link,
    proyectsText,
  } = props;
  return (
    <Card className={classes.root}>
      <CardActionArea
        style={{ minHeight: 450 }}
        onClick={() => setShowModalVideo({ open: true, position })}
      >
        {/* <CardMedia className={classes.media} image={image} title={title} /> */}
        <Image src={image} aspectRatio={16 / 9} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography gutterBottom variant="subtitle1" component="h6">
            {titleProduct}
          </Typography>
          <Typography gutterBottom variant="subtitle2" component="h6">
            {subtitle}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={() => setShowModalVideo({ open: true, position })}
        >
          {proyectsText.learnMore}
        </Button>
        {textButtonAction && (
          <Button size="small" color="primary" href={link} target="_blank">
            {textButtonAction}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default ProductCard;
ProductCard.defaultProps = {
  position: 0,
  image: '',
  title: '',
  subtitle: '',
  titleProduct: '',
  text: '',
  words: {},
  proyectsText: {},
  textButtonAction: '',
  link: '',
};

ProductCard.propTypes = {
  position: PropTypes.number,
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  titleProduct: PropTypes.string,
  text: PropTypes.string,
  textButtonAction: PropTypes.string,
  link: PropTypes.string,
  proyectsText: PropTypes.string,
  words: PropTypes.object,
  setShowModalVideo: PropTypes.func,
};
