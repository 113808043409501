import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../../../utils/Colors';
export default makeStyles((theme) => ({
  stylesText: {
    textAlign: 'center',
    fontFamily: 'Oranienbaum',
    color: '#ffffff',
  },
  divider: {
    border: 0,
    height: '3px',
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0), ${Colors.grayOne}, rgba(0, 0, 0, 0))`,
  },
}));
