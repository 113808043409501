/**
 * Tareas
 * Refactorizar el codigo, hacer que hayan reducers que menjen los errores
 * Colocar logo de la pestaña, cambiar el nombre de React.
 * COlocar que la parte de arriba en contactame no se pierda, cuando tiene el tamaño de mi celular.
 * Colocar una Cookies para contar la cantidad de correos ha enviar
 * Arreglar posiciones responsibas en el envio de mensajes y la pagina principal.
 * Colocar seccion de las empresas que he trabajado, colocar el logo, y cada
 * logo ser un link para expandir al informacióm.
 * Colocar seccion de productos realizado. orgnizandolos por empresa y tipo, (3D, Web. Movil), (React, Java, Blender, Unity, React-Naive, GraphQl)
 * */
import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { WhatsApp, Send } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import { isEmpty, get } from 'lodash';
import ModalConfirmMessage from '../ModalConfirmMessage/ModalConfirmMessage.jsx';
import useWords from '../../../../utils/Words';
import Classes from '../../Sytiles';

const sendFeedback = (elements) => {
  const {
    templateId,
    variables,
    setShowModal,
    showModal,
    setCountSends,
    countSends,
    words,
  } = elements;
  setShowModal({ ...showModal, loading: true });

  window.emailjs
    .send('gmail', templateId, variables)
    .then(() => {
      setCountSends(countSends + 1);
      setShowModal({
        ...showModal,
        loading: false,
        show: true,
        message: get(words, 'controlsLetter.successfulSend'),
      });
    })
    // Handle errors here however you like, or use a React error boundary
    .catch(() => {
      //   console.log('err>>', err);
      setShowModal({
        error: true,
        show: true,
        message: get(words, 'controlsLetter.errorSend'),
        loading: false,
      });
    });
};
const controlError = ({ letter, setLetter }) => {
  const { email, name, message, words } = letter;
  const fieldEmpty = get(words, 'controlsLetter.fieldEmpty');
  const messageCharactersError = get(
    words,
    'controlsLetter.messageCharactersError',
  );

  if (isEmpty(email.value)) {
    setLetter({
      ...letter,
      email: {
        ...email,
        error: {
          status: true,
          helperText: fieldEmpty,
        },
      },
    });
    return true;
  }
  if (isEmpty(name.value)) {
    setLetter({
      ...letter,
      name: {
        ...name,
        error: {
          status: true,
          helperText: fieldEmpty,
        },
      },
    });
    return true;
  }
  if (isEmpty(message.value)) {
    setLetter({
      ...letter,
      message: {
        ...message,
        error: {
          status: true,
          helperText: fieldEmpty,
        },
      },
    });
    return true;
  }

  if (message.value.length < 200 || message.value.length > 3000) {
    setLetter({
      ...letter,
      message: {
        ...message,
        error: {
          status: true,
          helperText:
            messageCharactersError + `${message.value.length} caracteres`,
        },
      },
    });
    return true;
  }

  return false;
};
const handleSubmit = (elements) => {
  const {
    letter,
    setShowModal,
    showModal,
    setCountSends,
    countSends,
    words,
  } = elements;
  const templateId = 'template_1u08YPG6';
  const variables = {
    message_html: get(letter, 'message.value'),
    from_name: get(letter, 'name.value'),
    reply_to: get(letter, 'email.value'),
  };
  sendFeedback({
    templateId,
    variables,
    setShowModal,
    showModal,
    setCountSends,
    countSends,
    words,
  });
};

const ContactMeForm = () => {
  const classes = Classes();
  const words = useWords();
  const [countSends, setCountSends] = useState(0); // pasar a una cookie
  const [letter, setLetter] = useState({
    email: { value: '', error: { status: false, helperText: '' } },
    name: { value: '', error: { status: false, helperText: '' } },
    message: { value: '', error: { status: false, helperText: '' } },
  });
  const [showModal, setShowModal] = useState({
    loading: false,
    error: false,
    message: '',
    show: false,
  });
  const loading = get(showModal, 'loading');
  const doesnSent = countSends < 4;
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid>
        <Row>
          <TextField
            id="outlined-multiline-flexible"
            label={get(words, 'letterForm.yourEmail')}
            placeholder="example@email.com"
            rowsMax={4}
            value={get(letter, 'email.value')}
            onChange={({ target: { value } }) =>
              setLetter({
                ...letter,
                email: { value, ...get(letter, 'email.value') },
              })
            }
            variant="outlined"
            style={{
              width: '50%',
            }}
            error={get(letter, 'email.error.status')}
            helperText={get(letter, 'email.error.helperText')}
          />
        </Row>
        <Row>
          <TextField
            id="outlined-textarea"
            label={get(words, 'letterForm.fullName')}
            placeholder={get(words, 'letterForm.Name')}
            variant="outlined"
            value={get(letter, 'name.value')}
            onChange={({ target: { value } }) =>
              setLetter({
                ...letter,
                name: { value, ...get(letter, 'name.value') },
              })
            }
            style={{
              width: '50%',
            }}
            error={get(letter, 'name.error.status')}
            helperText={get(letter, 'name.error.helperText')}
          />
        </Row>
        <Row>
          <TextField
            id="outlined-multiline-static"
            label={get(words, 'letterForm.yourMessage')}
            placeholder={get(words, 'letterForm.message')}
            multiline
            rows={10}
            defaultValue="Default Value"
            variant="outlined"
            value={get(letter, 'message.value')}
            onChange={({ target: { value } }) =>
              setLetter({
                ...letter,
                message: { value, ...get(letter, 'message.value') },
              })
            }
            style={{
              width: '90%',
              height: '90%',
            }}
            error={get(letter, 'message.error.status')}
            helperText={get(letter, 'message.error.helperText')}
          />
        </Row>
        <Row start="xs">
          <Col xs={6}>
            <Fab
              color="secondary"
              variant="extended"
              size={'large'}
              disabled={!doesnSent}
              className={classes.sendButton}
              onClick={() => {
                const error = controlError({ letter, setLetter, words });
                !error &&
                  handleSubmit({
                    letter,
                    setShowModal,
                    showModal,
                    setCountSends,
                    countSends,
                    words,
                  });
              }}
            >
              <Send className={classes.extendedIcon} />
              {get(words, 'letterForm.send')}
            </Fab>
          </Col>
          <Col xs={6}>
            <Fab
              color="secondary"
              target="_blank"
              href="http://www.wa.link/o9t9m5"
              className={classes.whatsappFab}
            >
              <WhatsApp className={classes.whatsappIcon} />
            </Fab>
          </Col>
        </Row>
        <Row style={{ marginTop: '2%' }} center={'xs'}>
          {loading && <CircularProgress />}
          <ModalConfirmMessage
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </Row>
      </Grid>
    </form>
  );
};

export default ContactMeForm;
