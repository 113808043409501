export default {
  balckOne: '#333',
  balckTwo: 'rgba(66,66,66, 0.7)',
  //IAS cards degraded
  grayOne: 'rgba(120, 120, 120, 0.8)',
  blueOne: 'rgba(12,13,33,1)',
  //IAS cards
  grayTwo: 'rgba(140, 140, 140, 0.5)',
  //SkillsSpider
  charcoal: 'rgb(52,60,73)',
  charcoalDarck: 'rgb(43 50 60)',
  //Chart Spider
  goldenOne: 'rgba(170,128,84,0.7)',
  goldenThree: 'rgba(235,213,127,0.9)',
  goldenThreeD: 'rgba(235,213,127,0.3)',
  goldenFour: 'rgb(255,255,172)',
  goldenTwo: 'rgba(224,185,165)',
  //Colors Charts IAS
  orangeOne: 'rgb(12,13,33)',
  orangeTwo: 'rgb(12,13,33)',
  yelloOne: '#6287BF',
  yelloTwo: '#6287BF',
};
