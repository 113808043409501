import React from 'react';
import ToolbarHeader from './AppBarHeader/AppBarHeader';
import App from './App';
import ContactMe from './ContactMe/ContactMe';
import { Route } from 'react-router-dom';

export const Routes = () => {
  const routes = [
    {
      exact: true,
      key: 'route-1',
      path: '/',
      navegation: () => {
        return <ToolbarHeader />;
      },
      main: () => {
        return <App />;
      },
    },
    {
      exact: true,
      key: 'route-2',
      path: '/Presentation',
      navegation: () => {
        return <ToolbarHeader />;
      },
      main: () => {
        return <App />;
      },
    },
    {
      exact: true,
      key: 'route-3',
      path: '/ContactMe',
      navegation: () => {
        return <ToolbarHeader />;
      },
      main: () => <ContactMe />,
    },
  ];

  return (
    <>
      <div className={'apa'}>
        {routes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            exact={route.exact}
            component={() => route.navegation()}
          />
        ))}
      </div>

      {routes.map((route) => (
        <Route
          key={route.key}
          path={route.path}
          exact={route.exact}
          component={route.main}
        />
      ))}
    </>
  );
};

export default Routes;
