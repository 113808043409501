// export default ContactMeForm;
import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { get } from 'lodash';
import PropTypes from 'prop-types';

const DescriptionAlerts = (props) => {
  const { showModal, setShowModal } = props;
  const error = get(showModal, 'error');
  const message = get(showModal, 'message');
  const show = get(showModal, 'show');
  return (
    <div>
      {error && show && (
        <Alert
          severity="error"
          onClose={() => {
            setShowModal({ error: false, show: false });
          }}
        >
          <AlertTitle>Error</AlertTitle>
          <p>{message}</p>
        </Alert>
      )}
      {show && !error && (
        <Alert
          severity="success"
          onClose={() => {
            setShowModal({ error: false, show: false });
          }}
        >
          <AlertTitle>Success</AlertTitle>
          <p>{message}</p>
        </Alert>
      )}
    </div>
  );
};

export default DescriptionAlerts;

DescriptionAlerts.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
};
