import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { get } from 'lodash';

import Colors from '../../../../utils/Colors';

const data = ({ words }) => ({
  labels: [get(words, 'personalResult'), get(words, 'toImprove')],
  datasets: [
    {
      backgroundColor: [Colors.orangeOne, Colors.yelloOne],
      borderColor: [Colors.orangeTwo, Colors.yelloTwo],
      borderWidth: 1,
    },
  ],
});

const constancyD = ({ words }) => ({
  ...data({ words }),
  datasets: [
    {
      ...data({ words })['datasets'][0],
      label: 'Constancia',
      data: [92, 8],
    },
  ],
});
const technicalExcellenceD = ({ words }) => ({
  ...data({ words }),
  datasets: [
    {
      ...data({ words })['datasets'][0],
      label: 'Constancia',
      data: [90, 10],
    },
  ],
});
const socialResponD = ({ words }) => ({
  ...data({ words }),
  datasets: [
    {
      ...data({ words })['datasets'][0],
      label: 'Responsabilidad Social',
      data: [92, 8],
    },
  ],
});
const respectD = ({ words }) => ({
  ...data({ words }),
  datasets: [
    {
      ...data({ words })['datasets'][0],
      label: 'Constancia',
      data: [100, 0],
    },
  ],
});
const legalityD = ({ words }) => ({
  ...data({ words }),
  datasets: [
    {
      ...data({ words })['datasets'][0],
      label: 'Legality',
      data: [98, 2],
    },
  ],
});
const teamworkD = ({ words }) => ({
  ...data({ words }),
  datasets: [
    {
      ...data({ words })['datasets'][0],
      label: `Trabajo En Equipo`,
      data: [87, 13],
    },
  ],
});

const options = {
  tooltips: {
    callbacks: {
      title: function (tooltipItem, data) {
        const value = data.datasets[0].data[tooltipItem[0]['index']];
        return `${data['labels'][tooltipItem[0]['index']]}: ${value}%`;
      },
      label: () => '',
    },
    backgroundColor: Colors.balckTwo,
    titleFontColor: '#fff',
    titleFontSize: 56,
    titleSpacing: 20,
    bodyFontColor: '#fff',
    bodyFontSize: 14,
    displayColors: false,
    titleFontStyle: 'Oranienbaum',
  },
  legend: {
    display: true,
    labels: {
      fontColor: '#ffff',
      fontFamily: 'Consolas',
      fontSize: 15,
    },
    position: 'bottom',
  },
  plugins: {
    datalabels: {
      color: '#ffff',
      formatter: function (value, context) {
        return value + '%';
      },
    },
  },
};

export default ({ words }) => {
  return {
    ias: [
      {
        title: get(words, 'ias.constancy.title'),
        icon: get(words, 'ias.constancy.icon'),
        chart: <Doughnut data={constancyD({ words })} options={options} />,
      },
      {
        title: get(words, 'ias.technicalExcellence.title'),
        icon: get(words, 'ias.technicalExcellence.icon'),
        chart: (
          <Doughnut data={technicalExcellenceD({ words })} options={options} />
        ),
      },
      {
        title: get(words, 'ias.socialRespon.title'),
        icon: get(words, 'ias.socialRespon.icon'),
        chart: <Doughnut data={socialResponD({ words })} options={options} />,
      },
      {
        title: get(words, 'ias.teamwork.title'),
        icon: get(words, 'ias.teamwork.icon'),
        chart: <Doughnut data={teamworkD({ words })} options={options} />,
      },
      {
        title: get(words, 'ias.legality.title'),
        icon: get(words, 'ias.legality.icon'),
        chart: <Doughnut data={legalityD({ words })} options={options} />,
      },
      {
        title: get(words, 'ias.respect.title'),
        icon: get(words, 'ias.respect.icon'),
        chart: <Doughnut data={respectD({ words })} options={options} />,
      },
    ],
  };
};
