import React from 'react';
import _ from 'lodash';
import { Col, Row } from 'react-flexbox-grid';
import { Paper } from '@material-ui/core';

import Classes from './Styles';

/* Utilities */
const un = require('../../../../utils/assets/UN.png');
const datum_logo = require('../../../../utils/assets/datum_logo.png');

const companies = {
  unal: {
    url: un,
    styles: {
      width: '50%',
    },
  },
  um: {
    url:
      'https://wipadweb.s3.amazonaws.com/uploads/client/logo/11/Logo_Universidad_de_Manizales.png',
    styles: {
      width: '60%',
      marginTop: -8,
    },
  },
  datum: {
    url: datum_logo,
    styles: {
      width: '80%',
      marginTop: 20,
    },
  },
  sura: {
    url:
      'https://segurossura.com.pa/wp-content/themes/SegurosSURA/assets/images/site/logo-sura.png',
    styles: {
      marginTop: 20,
      width: '80%',
    },
  },
};

const CardsText = () => {
  const classes = Classes();
  return _.map(companies, (company, key) => {
    return (
      <Col lg={2} md={2} xs={6} key={key + 'Companies'}>
        <Paper className={classes.cardCompany}>
          <img
            src={company.url}
            alt="universidad"
            className={classes.profileImage}
            style={company.styles}
          />
        </Paper>
      </Col>
    );
  });
};

const CardCompany = () => {
  const classes = Classes();
  return (
    <Row className={classes.row2} top={'xs'} middle={'xs'} center={'xs'}>
      <CardsText />
    </Row>
  );
};

export default CardCompany;
