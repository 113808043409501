import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

/* Utilities */
import ClassesStyles from './ClassesStyle';

const Title = ({ text, stylesText, stylesDivider }) => {
  const classes = ClassesStyles();
  return (
    <>
      <Typography
        variant="h2"
        component="h2"
        className={classes.stylesText}
        style={stylesText}
      >
        {text}
      </Typography>
      <hr className={classes.divider} style={stylesDivider} />
    </>
  );
};

export default Title;

Title.defaultProps = {
  stylesText: {},
  stylesDivider: {},
  text: '',
};

Title.propTypes = {
  text: PropTypes.string,
  stylesDivider: PropTypes.object,
  stylesText: PropTypes.object,
};
