import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Language from '@material-ui/icons/Language';

import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';

/* Utilities */
import Classes from '../../Sytiles';
import { useStorage } from '../../../../storage/index';
import useWords from '../../../../utils/Words';
import { changeLanguage, redirect } from './funtionsAppHeader';
import GroupButton from './GroupButton/GroupButton';

const handleClose = ({ setAnchorEl, language, dispatch }) => {
  setAnchorEl(false);
  changeLanguage({ dispatch, language });
};

const AppBarHeader = () => {
  const words = useWords();
  const { dispatch } = useStorage();
  const classes = Classes();
  const LogoJN = '< JNC />';
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);

  return (
    <AppBar elevation={5} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          className={classes.toolbarTitle}
          onClick={() => {
            redirect('/JNC/Presentation');
          }}
        >
          {LogoJN}
        </Typography>
        <GroupButton words={words} />
        <Button
          href="/JNC/ContactMe"
          color="secondary"
          variant="contained"
          className={classes.link}
        >
          {words.navigation.contactMe}
        </Button>

        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          color="inherit"
        >
          <Language />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
        >
          <MenuItem
            onClick={() => {
              handleClose({ setAnchorEl, language: 'Es', dispatch });
            }}
          >
            {words.navigation.language.spanish}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose({ setAnchorEl, language: 'En', dispatch });
            }}
          >
            {words.navigation.language.english}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose({ setAnchorEl, language: 'De', dispatch });
            }}
          >
            {words.navigation.language.german}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarHeader;
