import React from 'react';

import { Col, Row } from 'react-flexbox-grid';
import { Card, Typography } from '@material-ui/core';
import useWords from '../../../../utils/Words';
import ClassesStyles from './ClassesStyle';

const iasImagen = require('../../../../utils/assets/unal.png');

const CardCompany = () => {
  const words = useWords();
  const classes = ClassesStyles();
  return (
    <>
      <Row className={classes.containerEducation}>
        <Col lg={12}>
          <Typography
            variant="h2"
            component="h2"
            className={classes.titleEducation}
          >
            {words.education}
          </Typography>
          <hr className={classes.divider} />
          <Row>
            <Col lg={6}>
              <Card className={classes.containerCard}>
                <img src={iasImagen} alt="ias_image" width="200" />
                <hr className={classes.divider} />
                <Typography
                  variant="h5"
                  component="h5"
                  className={classes.titleEducation}
                >
                  {words.collegeDgreeOne}
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.titleEducation}
                >
                  {words.yearsOne}
                </Typography>
              </Card>
            </Col>
            <Col lg={6}>
              <Card className={classes.containerCard}>
                <img src={iasImagen} alt="ias_image" width="200" />
                <hr className={classes.divider} />
                <Typography
                  variant="h5"
                  component="h5"
                  className={classes.titleEducation}
                >
                  {words.collegeDgreeTwo}
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.titleEducation}
                >
                  {words.yearsTwo}
                </Typography>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CardCompany;
