import React from 'react';
import { get } from 'lodash';

import {
  LocalLibrary,
  HowToReg,
  SupervisorAccount,
  GroupWork,
  Gavel,
  Hearing,
} from '@material-ui/icons';
import { useStorage } from '../storage/index';

const spanish = {
  navigation: {
    presentation: 'Precentación',
    contactMe: 'Contáctame',
    language: {
      spanish: 'Español',
      english: 'Inglés',
      german: 'Aleman',
    },
  },
  openMenu: 'Abrir Menu',
  optionsMenu: {
    presentation: 'Presentación',
    skills: 'Habilidades',
    companies: 'Empresas',
    proyects: 'Proyectos',
  },
  presentation: {
    title: 'Desarrollador Web y Móvil',
    p1: `Llevo más de 4 años programando y no paro de hacerlo porque es algo que me apasiona si no me encuentro realizando una página web o una aplicación móvil, 
         me encuentro mejorando mis skills de desarrollo TI y si no leyendo sobre innovaciones tecnológicas que estén surgiendo en el mercado. 
         Ya sea para proponer ideas innovadoras o para expandir mi conocimiento.`,
    p2: ` Conmigo todo se trata de mejora continua, afrontar problemas tecnológicos, estableciendo propuestas que sean consistentes en tiempo, costo y alcance.
          Esto mantiene mi trabajo emocionante y mi equipo enfocado. A través de una buena planeación y asignación de tareas consistentes los objetivos 
          que se tracen serán cumplidos en el tiempo estipulado.`,
  },
  letter: {
    p1: ` Es para mi un gran honor que te tomes el tiempo de enviarme un mensaje, espero pronto dar solución a tus necesidades. `,
  },
  letterForm: {
    yourEmail: 'Tu email',
    fullName: 'Tu nombre completo',
    Name: 'Nombre completo',
    yourMessage: ' Tu mensaje',
    message: 'Mensaje',
    send: 'Enviar',
  },
  controlsLetter: {
    errorSend: `El mensaje, no fue posible enviarlo, si deseas, puedes enviarlo al siguiente correo: 
    21sebas12@gmail.com`,
    successfulSend: `El mensaje, fue enviado correctamente, pronto estaré dando respuesta, gracias.`,
    emailError: 'Por favor ingrese correctamente el correo electrónico',
    messageCharactersError:
      'El mensaje debe contener entre 200 y 1000 caracteres, su mensaje cuenta con: ',
    fieldEmpty: 'Por Favor diligencie este campo',
  },
  ias: {
    constancy: {
      icon: <LocalLibrary />,
      title: `Constancia`,
      paragraphs: [
        `Es capaz de superar las adversidades que se producen durante el trabajo; No se desanima con facilidad y busca encontrar alternativas de solución a los problemas.`,
      ],
    },
    technicalExcellence: {
      icon: <HowToReg />,
      title: `Excelencia Técnica `,
      paragraphs: [
        `Es metódico para realizar el trabajo, logra establecer prioridades y en el producto de su trabajo se puede evidenciar la planeación previa.`,
        `Logra cumplir en los tiempos acordados el trabajo que le fue encomendado, con resultados de buena calidad.`,
        `Se capacita constantemente y logra aplicar los nuevos conocimientos en mejoras en el desempeño laboral.`,
      ],
    },
    socialRespon: {
      icon: <SupervisorAccount />,
      title: `Responsabilidad Social`,
      paragraphs: [
        `Impacta en el mejoramiento de la empresa, es parte activa de los equipos que participa.`,
      ],
    },
    teamwork: {
      icon: <GroupWork />,
      title: `Trabajo En Equipo`,
      paragraphs: [
        `Es flexible en las formas de alcanzar los logros propuestos en el equipo que participa.`,
        `Motiva entre sus compañeros la responsabilidad para cumplimiento de los compromisos.`,
        `Se interesa por trabajar con otras personas y en conjunto desde la cooperación lograr las metas propuestas.`,
      ],
    },
    legality: {
      icon: <Gavel />,
      title: `Legalidad`,
      paragraphs: [
        `Actúa de acuerdo con las normas de la empresa.`,
        `Cumple con el código de vestuario del entorno en el que trabaja.`,
        `Cumple con los acuerdos de horario establecidos con sus compañeros o clientes.`,
        `Utiliza eficientemente los recursos asignados para llevar a cabo sus actividades.`,
      ],
    },
    respect: {
      icon: <Hearing />,
      title: `Respeto`,
      paragraphs: [
        `Es respetuoso, genera ambientes adecuados y de colaboración.`,
        `Trata a todas las personas por igual, valora el trabajo de otros y recomienda corregir los errores que encuentra.`,
      ],
    },
  },
  personalResult: 'Resultado Personal',
  toImprove: 'Por mejorar',
  contactMe: {
    thanks: 'Gracias!',
  },
  lastCompany: 'Ultimas Compañias',
  education: 'Educación Superior',
  collegeDgreeOne: 'Administración de Sistemas Informáticos',
  yearsOne: '5 años',
  culminatedOne: 'Culminado',
  collegeDgreeTwo:
    'Estudios de Maestría en Administración de Sistemas Informáticos',
  yearsTwo: '2 años',
  culminatedTwo: 'No Culminado',
  skills: {
    mySkills: 'Mis habilidades ',
    managementSkill: 'Habilidades de Gestión',
    management: 'Gestión',
    skillsManagement: [
      {
        skill: 'Modelos de Control',
        level: 3.5,
      },
      {
        skill: 'Full Stack',
        level: 4,
      },
      {
        skill: 'Líder de proyectos',
        level: 3.6,
      },
      {
        skill: 'Director de tecnología',
        level: 3.5,
      },
      {
        skill: 'Investigación',
        level: 4.5,
      },
      {
        skill: 'Estados financieros',
        level: 3.5,
      },
      {
        skill: 'Metodología ágil',
        level: 3.5,
      },
      {
        skill: 'Análisis estadístico',
        level: 4,
      },
    ],
    socialSkill: 'Habilidades Sociales',
    social: 'Sociales',
    skillsSocial: [
      {
        skill: 'Empatía',
        level: 4.5,
      },
      {
        skill: 'Liderazgo',
        level: 4.7,
      },
      {
        skill: 'Tolerancia',
        level: 4,
      },
      {
        skill: 'Adaptación al cambio.',
        level: 4,
      },
      {
        skill: 'Confiar',
        level: 5,
      },
      {
        skill: 'Comunicación asertiva',
        level: 4,
      },
      {
        skill: 'Escucha',
        level: 4,
      },
      {
        skill: 'Colaborativo',
        level: 4.7,
      },
    ],
    otherSkill: 'Otras Habilidades',
    others: 'Otras',
    otherSkills: [
      {
        skill: 'Español',
        level: 4.8,
      },
      {
        skill: 'Adobe Premiere',
        level: 3.5,
      },
      {
        skill: 'Adobe Pothoshop',
        level: 3.5,
      },
      {
        skill: 'Google AdWords',
        level: 3.5,
      },
      {
        skill: 'SPSS',
        level: 3.5,
      },

      {
        skill: 'ofimática',
        level: 4.8,
      },
      {
        skill: 'Unity (3D)',
        level: 3,
      },
      {
        skill: 'Blender (3D)',
        level: 3,
      },
      {
        skill: 'Cobit 4',
        level: 3,
      },
      {
        skill: 'Ingles',
        level: 3.5,
      },
    ],
    thecnicalSkill: 'Habilidades Técnicas',
    thecnical: 'Técnicas',
  },
  proyectsText: {
    proyects: 'Proyectos',
    learnMore: 'Conocer más',
    see: 'Ver',
    download: 'Descargar',
    unalProyect: {
      company: 'Universidad Nacional de Colombia',
      description:
        'Este proyecto fue desarrollado en el grupo de investigación GAIA de la Universidad Nacional de Colombia.',
      p1: 'Plataforma Chaea',
    },
    suraProyect: {
      company: 'Sura',
      description:
        'Este proyecto se realizó en tecnologías como Java 8, React JS y Oracle y se implementó la librería react-pdf-simulator la cual es de mi autoría.',
      p1: 'Facturación electrónica',
    },
    npmProyectPdf: {
      company: 'Librería MIT NPM',
      description:
        'Esta librería fue creada para la comunidad, básicamente se trata de establecer cualquier tipo de código HTML y mostrarlo en una ventana similar a la que muestra un pdf cuando se imprime en el navegador.',
      p1: 'react-pdf-simulator',
    },
    bikey: {
      company: 'IAS Software',
      descriptionOne: `Se trata de un proyecto de demo creado desde el área de I+D+I en la empresa IAS software, se trata básicamente de registrar usuario mediante reconocimiento facial.`,
      descriptionTwo: `Se trata de un proyecto de demo creado desde el área de I+D+I en la empresa IAS software, se trata básicamente de registrar usuario mediante reconocimiento facial. 
        Tecnologías utilizadas: React Native, Android, NodeJS, GraphQL, AWS`,
      p1: 'Bikey',
    },
    mallSteps: {
      company: 'IAS Software',
      description: `Se trata de un proyecto de demo creado desde el área de I+D+I en la empresa IAS software`,
      p1: 'Mallsteps',
    },
    typesProyects: {
      webApp: 'Aplicación Web',
      app: 'Aplicación movil',
      ARapp: 'Aplicación de Realidad Aumentada',
    },
  },
};

const english = {
  navigation: {
    presentation: 'Presentation',
    contactMe: 'Contact Me',
    language: {
      spanish: 'Spanish',
      english: 'English',
      german: 'German',
    },
  },
  openMenu: 'Open Menu',
  optionsMenu: {
    presentation: 'Presentation',
    skills: 'Skills',
    companies: 'Companies',
    proyects: 'Projects',
  },
  lastCompany: 'Last Companies',
  presentation: {
    title: 'Web & Mobile Developer',
    p1: `I've been programming for more than four years, and I won't stop doing it because I'm passionate about this. If I'm not making a website or a mobile application, I found myself improving my IT development skills or reading about technological innovations emerging in the market. Either to propose innovative ideas or to expand my knowledge.`,
    p2: `Everything is about continuous improvement, facing technological problems, establishing proposals that are consistent on time, cost, and scope. It makes my work more exciting, and my team focused. Through good planning and consistent homework assignments, goals will complete traced goals on the stipulated time.`,
  },
  letter: {
    p1: ` It's a great honor for me that you are taking the time to send me a message, I will answer you as soon as possible.`,
  },
  letterForm: {
    yourEmail: 'Your email',
    fullName: 'Your Full Name',
    Name: 'Full Name',
    yourMessage: 'Your message',
    message: 'Message',
    send: 'Send',
  },
  controlsLetter: {
    errorSend: `The message is no sent. If you wish, you can send it to the following email:
    jcioweb@gmail.com`,
    successfulSend: `Your message arrived well. I will come back to you as soon as possible. Thank you!`,
    emailError: 'Please enter the email correctly.',
    messageCharactersError:
      'The message must contain between 200 and 3000 characters, your message has: ',
    fieldEmpty: 'Please fill in this field',
  },
  ias: {
    constancy: {
      icon: <LocalLibrary />,
      title: `Constancy`,
      paragraphs: [
        `He can overcome adversities when is necessary during work.`,
        `He is not easily discouraged and seeks alternative solutions to problems.`,
      ],
    },
    technicalExcellence: {
      icon: <HowToReg />,
      title: `Technical excellence`,
      paragraphs: [
        `He is efficient in setting priorities for carrying out excellent work. it's evident if you check his work out, and you observe there was previous planning.`,
        `He manages time to complete his work at the agreed time with excellent quality results.`,
        `He is constantly improving himself to obtain new knowledge. In this way, he can continue applying this knowledge to his job performance.`,
      ],
    },
    socialRespon: {
      icon: <SupervisorAccount />,
      title: `Social responsability`,
      paragraphs: [
        `He generates an important influence on the improvement of the company and also is an active participant of the work teams`,
      ],
    },
    teamwork: {
      icon: <GroupWork />,
      title: `Teamwork`,
      paragraphs: [
        `He is flexible in the ways of achieving the proposed achievements in the participating team.`,
        `Motivates among his colleagues the responsibility for completing the commitments.`,
        `He is interested in working with other people, from cooperation, achieving the proposed goals.`,
      ],
    },
    legality: {
      icon: <Gavel />,
      title: `Legality`,
      paragraphs: [
        `Acts according to company regulations.`,
        `Complies with the dress code of the environment in which he works.`,
        `Complies with the established colleagues and clients time agreements.`,
        `Manages resources assigned to carry out its activities efficiently.`,
      ],
    },
    respect: {
      icon: <Hearing />,
      title: `Respect`,
      paragraphs: [
        `He is respectful, generates suitable and collaborative environments.`,
        `He treats all people equally, furthermore values the work of others and recommends correcting the mysteries found.`,
      ],
    },
  },
  personalResult: 'Personal Result',
  toImprove: 'To Improve',
  contactMe: {
    thanks: 'Thanks!',
  },
  education: 'Higher Education',
  collegeDgreeOne: 'Computer Systems Administration',
  yearsOne: '5 years',
  culminatedOne: 'Culminated',
  collegeDgreeTwo: 'Master Studies in Computer Systems Administration',
  yearsTwo: '2 years',
  culminatedTwo: 'Not Completed',
  skills: {
    mySkills: 'My Skills ',
    managementSkill: 'Management Skills',
    management: 'Management',
    skillsManagement: [
      {
        skill: 'Control Models',
        level: 3.5,
      },
      {
        skill: 'Full Stack',
        level: 4,
      },
      {
        skill: 'Project Manager',
        level: 3.6,
      },
      {
        skill: 'CTO',
        level: 3.5,
      },
      {
        skill: 'Investigation',
        level: 4.5,
      },
      {
        skill: 'Financial Statements',
        level: 3.5,
      },
      {
        skill: 'Agile Methologic',
        level: 3.5,
      },
      {
        skill: 'Statistical Analysis',
        level: 4,
      },
    ],
    socialSkill: 'Social Skills',
    social: 'Social',
    skillsSocial: [
      {
        skill: 'Empathy',
        level: 4.5,
      },
      {
        skill: 'Leadership',
        level: 4.7,
      },
      {
        skill: 'Tolerance',
        level: 4,
      },
      {
        skill: 'Adaptation to change',
        level: 4,
      },
      {
        skill: 'Trust',
        level: 5,
      },
      {
        skill: 'Assertive Communication',
        level: 4,
      },
      {
        skill: 'Listen',
        level: 4,
      },
      {
        skill: 'Collaborative',
        level: 4.7,
      },
    ],
    otherSkill: 'Others Skills',
    others: 'Others',
    otherSkills: [
      {
        skill: 'Spanish',
        level: 4.8,
      },
      {
        skill: 'Adobe Premiere',
        level: 3.5,
      },
      {
        skill: 'Adobe Pothoshop',
        level: 3.5,
      },
      {
        skill: 'Google AdWords',
        level: 3.5,
      },
      {
        skill: 'SPSS',
        level: 3.5,
      },

      {
        skill: 'office automation',
        level: 4.8,
      },
      {
        skill: 'Unity (3D)',
        level: 3,
      },
      {
        skill: 'Blender (3D)',
        level: 3,
      },
      {
        skill: 'Cobit 4',
        level: 3,
      },
      {
        skill: 'English',
        level: 3.5,
      },
    ],
    thecnicalSkill: 'Thecnical Skills',
    thecnical: 'Thecnical',
  },
  proyectsText: {
    proyects: 'Projects',
    learnMore: 'Learn More',
    see: 'See',
    download: 'Download',
    unalProyect: {
      company: "Colombia's National University",
      description:
        "This project was developed in the GAIA research group of the Colombia's National University",
      p1: 'Chaea Platform',
    },
    npmProyectPdf: {
      company: 'MIT NPM Library',
      description:
        'This library was created for the community, it basically deals with establishing any type of HTML code and displaying it in a window similar to the one that shows a pdf when printed in browser.',
      p1: 'react-pdf-simulator',
    },
    suraProyect: {
      company: 'Sura',
      description:
        'This project was carried out in technologies such as Java 8, React JS and Oracle and implemented the react-pdf-simulator library which is my authorship.',
      p1: 'Electronic billing',
    },
    bikey: {
      company: 'IAS Software',
      descriptionOne: `It is a demo project created from the R & D & I zone at the IAS software company, it is basically a question of registering user through facial recognition.`,
      descriptionTwo: `It is a demo project created from the R & D & I zone at the IAS software company, it is basically a question of registering user through facial recognition.
      Technologies used: React Native, Android, NodeJS, GraphQL, Sequelize, AWS`,
      p1: 'Bikey',
    },
    mallSteps: {
      company: 'IAS Software',
      description: `It is a demo project created from the R & D & I zone at the IAS software company.`,
      p1: 'Mallsteps',
    },
    typesProyects: {
      webApp: 'Web Application',
      app: 'Mobile app',
      ARapp: 'Augmented Reality App',
    },
  },
};
const deutsche = {
  navigation: {
    presentation: 'Präsentation',
    contactMe: 'Kontaktiere mich',
    language: {
      spanish: 'Spanisch',
      english: 'Englisch',
      german: 'Deutsche',
    },
  },
  openMenu: 'Menü öffnen',
  optionsMenu: {
    presentation: 'Präsentation',
    skills: 'Kompetenzen',
    companies: 'Unternehmen',
    proyects: 'Projekte',
  },
  lastCompany: 'Letzte Unternehmen',
  presentation: {
    title: 'Web & Mobile Entwickler',
    p1: `Ich programmiere seit mehr als 4 Jahren und ich werde nicht aufhören, es zu tun, weil es etwas ist, das mich begeistert. Wenn ich keine Website oder mobile Anwendung erstelle, habe ich meine IT-Entwicklungsfähigkeiten verbessert oder über technologische Innovationen gelesen, die auf dem Markt auftauchen. Entweder um innovative Ideen vorzuschlagen oder um mein Wissen zu erweitern.`,
    p2: `Ich denke, bei allem geht es um kontinuierliche Verbesserungen, um technologische Probleme und um die Erstellung von Vorschlägen, die hinsichtlich Zeit, Kosten und Umfang konsistent sind. Das macht meine Arbeit spannender und mein Team fokussiert. Durch gute Planung und konsequente Hausaufgaben werden die verfolgten Ziele zum festgelegten Zeitpunkt erreicht.`,
  },
  letter: {
    p1: `Es ist mir eine große Ehre, dass Sie sich die Zeit nehmen, mir eine Nachricht zu senden. Ich hoffe, Ihre Bedürfnisse so schnell wie möglich zu lösen.`,
  },
  letterForm: {
    yourEmail: 'Deine E-Mail',
    fullName: 'Ihr vollständiger Name',
    Name: 'Vollständiger Nachname',
    yourMessage: 'Deine Nachricht',
    message: 'Botschaft',
    send: 'Senden',
  },
  controlsLetter: {
    errorSend: `Die Nachricht konnte nicht gesendet werden. Wenn Sie möchten, können Sie es an folgende E-Mail senden:
    jcioweb@gmail.com`,
    successfulSend: `Die Nachricht wurde korrekt gesendet. Ich werde dir bald antworten, danke.`,
    emailError: 'Bitte geben Sie die E-Mail korrekt ein.',
    messageCharactersError:
      'Die Nachricht muss zwischen 200 und 3000 Zeichen enthalten. Ihre Nachricht enthält: ',
    fieldEmpty: 'Bitte füllen Sie dieses Feld aus',
  },
  ias: {
    constancy: {
      icon: <LocalLibrary />,
      title: `Konstanz`,
      paragraphs: [
        `Er kann Widrigkeiten überwinden, wenn dies während der Arbeit notwendig ist.`,
        `Er lässt sich nicht leicht entmutigen und sucht nach alternativen Lösungen für Probleme.`,
      ],
    },
    technicalExcellence: {
      icon: <HowToReg />,
      title: `Technische Exzellenz`,
      paragraphs: [
        `Er setzt effizient Prioritäten für hervorragende Arbeit. Es ist offensichtlich, wenn Sie seine Arbeit überprüfen und feststellen, dass zuvor geplant wurde.`,
        `Er schafft Zeit, um seine Arbeit zum vereinbarten Zeitpunkt mit hervorragenden Qualitätsergebnissen abzuschließen.`,
        `Er verbessert sich ständig, um neues Wissen zu erlangen. Auf diese Weise kann er dieses Wissen weiterhin auf seine Arbeitsleistung übertragen.`,
      ],
    },
    socialRespon: {
      icon: <SupervisorAccount />,
      title: `Gesellschaftliche Verantwortung`,
      paragraphs: [
        `Er hat einen wichtigen Einfluss auf die Verbesserung des Unternehmens und ist auch ein aktiver Teilnehmer der Arbeitsteams.`,
      ],
    },
    teamwork: {
      icon: <GroupWork />,
      title: `Zusammenarbeit`,
      paragraphs: [
        `Er ist flexibel in der Art und Weise, wie die vorgeschlagenen Erfolge im teilnehmenden Team erreicht werden.`,
        `Motiviert unter seinen Kollegen die Verantwortung für die Erfüllung der Verpflichtungen.`,
        `Er ist daran interessiert, mit anderen Menschen zusammenzuarbeiten, um die vorgeschlagenen Ziele zu erreichen.`,
      ],
    },
    legality: {
      icon: <Gavel />,
      title: `Rechtmäßigkeit`,
      paragraphs: [
        `Handelt nach Unternehmensvorschriften.`,
        `Entspricht der Kleiderordnung der Umgebung, in der er arbeitet.`,
        `Entspricht den festgelegten Zeitvereinbarungen für Kollegen und Kunden.`,
        `Verwaltet Ressourcen, die zur effizienten Ausführung ihrer Aktivitäten zugewiesen wurden.`,
      ],
    },
    respect: {
      icon: <Hearing />,
      title: `Respekt`,
      paragraphs: [
        `Er ist respektvull,generiert geeignete und kollaborative Umgebungen.`,
        `Er behandelt alle Menschen gleich, schätzt außerdem die Arbeit anderer und empfiehlt, die gefundenen Fehler zu korrigieren.`,
      ],
    },
  },
  personalResult: 'Persönliches Ergebnis',
  toImprove: 'Verbessern',
  contactMe: {
    thanks: 'Danke!',
  },
  education: 'Höhere Bildung',
  collegeDgreeOne: 'Verwaltung von Computersystemen',
  yearsOne: '5 Jahre',
  culminatedOne: 'Höhepunkt',
  collegeDgreeTwo: 'Masterstudium in Computer System Administration',
  yearsTwo: '2 Jahre',
  culminatedTwo: 'Nicht vollständig',
  skills: {
    mySkills: 'Meine Fähigkeiten ',
    managementSkill: 'Führungsqualitäten',
    management: 'Management',
    skillsManagement: [
      {
        skill: 'Steuerungsmodelle',
        level: 3.5,
      },
      {
        skill: 'Voller Stapel',
        level: 4,
      },
      {
        skill: 'Projektmanager',
        level: 3.6,
      },
      {
        skill: 'CTO',
        level: 3.5,
      },
      {
        skill: 'Untersuchung',
        level: 4.5,
      },
      {
        skill: 'Jahresabschluss',
        level: 3.5,
      },
      {
        skill: 'Agile Methoden',
        level: 3.5,
      },
      {
        skill: 'Statistische Analyse',
        level: 4,
      },
    ],
    socialSkill: 'Soziale Fähigkeiten',
    social: 'Soziale',
    skillsSocial: [
      {
        skill: 'Empathie',
        level: 4.5,
      },
      {
        skill: 'Führung',
        level: 4.7,
      },
      {
        skill: 'Toleranz',
        level: 4,
      },
      {
        skill: 'Anpassung an Veränderungen',
        level: 4,
      },
      {
        skill: 'zuversichtlich',
        level: 5,
      },
      {
        skill: 'Selbstsichere Kommunikation',
        level: 4,
      },
      {
        skill: 'Hör mal zu',
        level: 4,
      },
      {
        skill: 'Kollaborativ',
        level: 4.7,
      },
    ],
    otherSkill: 'Andere Fähigkeiten',
    others: 'Others',
    otherSkills: [
      {
        skill: 'Spanisch',
        level: 4.8,
      },
      {
        skill: 'Adobe Premiere',
        level: 3.5,
      },
      {
        skill: 'Adobe Photoshop',
        level: 3.5,
      },
      {
        skill: 'Google AdWords',
        level: 3.5,
      },
      {
        skill: 'SPSS',
        level: 3.5,
      },

      {
        skill: 'office automation',
        level: 4.8,
      },
      {
        skill: 'Unity (3D)',
        level: 3,
      },
      {
        skill: 'Blender (3D)',
        level: 3,
      },
      {
        skill: 'Cobit 4',
        level: 3,
      },
      {
        skill: 'Englisch',
        level: 3.5,
      },
    ],
    thecnicalSkill: 'Technische Fähigkeiten',
    thecnical: 'Technische',
  },
  proyectsText: {
    proyects: 'Projekte',
    learnMore: 'Learn More',
    see: 'Sehen',
    download: 'Herunterladen',
    unalProyect: {
      company: 'Kolumbiens Nationaluniversität',
      description:
        'Dieses Projekt wurde in der GAIA-Forschungsgruppe der National University in Kolumbien entwickelt',
      p1: 'Chaea Platform',
    },
    npmProyectPdf: {
      company: 'MIT NPM Bibliothek',
      description:
        'Diese Bibliothek wurde für die Community erstellt. Sie befasst sich im Wesentlichen mit der Einrichtung aller Arten von HTML-Code und deren Anzeige in einem Fenster, das dem Fenster ähnelt, in dem beim Drucken im Browser ein PDF angezeigt wird',
      p1: 'reaktions-pdf-simulator',
    },
    suraProyect: {
      company: 'Sura',
      description:
        'Dieses Projekt wurde in Technologien wie Java 8, React JS und Oracle durchgeführt und implementierte die React-PDF-Simulator-Bibliothek, die meine Urheberschaft ist.',
      p1: 'Elektronische Abrechnung',
    },
    bikey: {
      company: 'IAS Software',
      descriptionOne: `Es handelt sich um ein Demo-Projekt, das aus der F & E- und I-Zone des IAS-Softwareunternehmens erstellt wurde. Grundsätzlich geht es darum, Benutzer durch Gesichtserkennung zu registrieren.`,
      descriptionTwo: `Es handelt sich um ein Demo-Projekt, das aus der F & E- und I-Zone des IAS-Softwareunternehmens erstellt wurde. Grundsätzlich geht es darum, Benutzer durch Gesichtserkennung zu registrieren.
      Verwendete Technologien: React Native, Android, NodeJS, GraphQL, Sequelize, AWS`,
      p1: 'Bikey',
    },
    mallSteps: {
      company: 'IAS Software',
      description: `Es handelt sich um ein Demo-Projekt, das aus der F & E- und I-Zone des IAS-Softwareunternehmens erstellt wurde.`,
      p1: 'Mallsteps',
    },
    typesProyects: {
      webApp: 'Internetanwendung',
      app: 'Mobile App',
      ARapp: 'Augmented Reality App',
    },
  },
};

function getLanguageWords({ storage }) {
  const language = get(storage, 'language');
  switch (language) {
    case 'Es':
      return {
        ...spanish,
      };
    case 'En':
      return {
        ...english,
      };
    case 'De':
      return {
        ...deutsche,
      };
    default:
      return {
        ...english,
      };
  }
}

export default function useWords() {
  const { storage } = useStorage();
  return getLanguageWords({ storage });
}
