//https://material-ui.com/es/components/speed-dial/
import React from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Build from '@material-ui/icons/Build';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import Whatshot from '@material-ui/icons/Whatshot';
import DeviceHub from '@material-ui/icons/DeviceHub';
import PropTypes from 'prop-types';
import Classes from '../../Sytiles';
import { get } from 'lodash';

const actions = ({ setCodeSkills, words }) => [
  {
    icon: <DeviceHub />,
    name: get(words, 'skills.management'),
    handleOnClick: () => setCodeSkills('management'),
  },
  {
    icon: <Build />,
    name: get(words, 'skills.thecnical'),
    handleOnClick: () => setCodeSkills('thecnical'),
  },
  {
    icon: <SupervisorAccount />,
    name: get(words, 'skills.social'),
    handleOnClick: () => setCodeSkills('social'),
  },
  {
    icon: <Whatshot />,
    name: get(words, 'skills.others'),
    handleOnClick: () => setCodeSkills('otherSkills'),
  },
];
const selectIcon = ({ codeSkills }) => {
  switch (codeSkills) {
    case 'management': {
      return <DeviceHub />;
    }
    case 'thecnical': {
      return <Build />;
    }
    case 'social': {
      return <SupervisorAccount />;
    }
    case 'otherSkills': {
      return <Whatshot />;
    }
    default: {
      return <SpeedDialIcon />;
    }
  }
};
export const MenuButton = (props) => {
  const { setCodeSkills, codeSkills, words } = props;
  const classes = Classes();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(open ? false : true);
  };

  const handleOpen = () => {
    setOpen(open ? false : true);
  };

  return (
    <div className={classes.rootFlabMenu}>
      <div className={classes.exampleWrapper}>
        <SpeedDial
          ariaLabel="SpeedDial example"
          className={classes.speedDial}
          hidden={false}
          icon={selectIcon({ codeSkills })}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction={'down'}
          FabProps={{
            className: classes.buttonGoldenColor,
            color: 'secondary',
          }}
        >
          {actions({ setCodeSkills, words }).map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={() => {
                action.handleOnClick();
              }}
            />
          ))}
        </SpeedDial>
      </div>
    </div>
  );
};
export default MenuButton;

MenuButton.propTypes = {
  setCodeSkills: PropTypes.func,
  codeSkills: PropTypes.string,
  words: PropTypes.object,
};
