import chaea from '../../../../utils/assets/Miniaturas/chaea.png';
import redTrofica from '../../../../utils/assets/Miniaturas/Red_Trofica.jpg';
import problemasAmbientales from '../../../../utils/assets/Miniaturas/Problemas_Ambientales.jpg';
import energiaAlternativa from '../../../../utils/assets/Miniaturas/Energia_Alternativa.jpg';
import revolucionIndustrial from '../../../../utils/assets/Miniaturas/RevolucionIndustrial.jpg';
import eraNapoleonica from '../../../../utils/assets/Miniaturas/Era_Napoleonica.jpg';
import cicloDelAgua from '../../../../utils/assets/Miniaturas/Ciclo_del_Agua.jpg';
import regionPacifico from '../../../../utils/assets/Miniaturas/Region_Pacifico.jpg';
import fotosintesis from '../../../../utils/assets/Miniaturas/Fotosintesis.jpg';
import estadosMateria from '../../../../utils/assets/Miniaturas/Estados_de_la_Materia.jpg';
import regionOrinoquia from '../../../../utils/assets/Miniaturas/Region_Orinoquia.jpg';
import andina from '../../../../utils/assets/Miniaturas/Andina.jpg';
import edadMedia from '../../../../utils/assets/Miniaturas/Edad_Media.jpg';
import regionInsular from '../../../../utils/assets/Miniaturas/Region_Insular.jpg';
import meiosis from '../../../../utils/assets/Miniaturas/Meiosis.jpg';
import biomas from '../../../../utils/assets/Miniaturas/Biomas.jpg';
import suraApp from '../../../../utils/assets/Miniaturas/SuraApp.png';
import reactPdf from '../../../../utils/assets/Miniaturas/react-pdf-simulator.png';
import bikey from '../../../../utils/assets/Miniaturas/Bikey.jpg';
import mallteps from '../../../../utils/assets/Miniaturas/mallsteps.png';

export const skillsThecnical = [
  {
    skill: 'HTML',
    level: 4.6,
  },
  {
    skill: 'CSS',
    level: 4.2,
  },
  {
    skill: 'Javascript',
    level: 4.8,
  },
  {
    skill: 'PHP',
    level: 4,
  },
  {
    skill: 'C',
    level: 4,
  },
  {
    skill: 'JAVA',
    level: 4.5,
  },
  {
    skill: 'Python',
    level: 3,
  },
  {
    skill: 'Drat',
    level: 3.5,
  },
  {
    skill: '.Net',
    level: 3,
  },
  {
    skill: 'Sequelize',
    level: 4,
  },
  {
    skill: 'GraphQL',
    level: 4.2,
  },
  {
    skill: 'MongoDB (DB)',
    level: 3.5,
  },
  {
    skill: 'Postgresql (DB)',
    level: 4.8,
  },
  {
    skill: 'Access (DB)',
    level: 4.5,
  },
  {
    skill: 'SQlite (DB)',
    level: 4.8,
  },
  {
    skill: 'ANSI SQL',
    level: 4.5,
  },
  {
    skill: 'Scriptcase (PHP)',
    level: 4,
  },
  {
    skill: 'Photoshop',
    level: 4.4,
  },
  {
    skill: 'DynamoDB (AWS)',
    level: 3.5,
  },
  {
    skill: 'Pinpoint (AWS)',
    level: 3.5,
  },
  {
    skill: 'S3 (AWS)',
    level: 4,
  },
  {
    skill: 'Cognito (AWS)',
    level: 4,
  },
  {
    skill: 'Amplify (AWS)',
    level: 4,
  },
  {
    skill: 'Spring Boot (JAVA)',
    level: 4,
  },
  {
    skill: 'RX (JS, JAVA)',
    level: 4,
  },
  {
    skill: 'jQuery (JS)',
    level: 4,
  },
  {
    skill: 'React (JS)',
    level: 4.8,
  },
  {
    skill: 'Redux (JS)',
    level: 4.6,
  },
  {
    skill: 'React Native (JS)',
    level: 4.8,
  },
  {
    skill: 'NodeJS (JS)',
    level: 4.6,
  },

  {
    skill: 'NPM',
    level: 4.8,
  },
  {
    skill: 'GIT',
    level: 4.5,
  },
];

export const productsf = (elements) => {
  const { setShowModalVideo, proyectsText } = elements;
  return [
    {
      title: proyectsText.mallSteps.company,
      text: proyectsText.mallSteps.description,
      subtitle: proyectsText.typesProyects.app,
      textVideo: proyectsText.mallSteps.description,
      image: mallteps,
      setShowModalVideo,
      textButtonAction: '',
      titleProduct: proyectsText.mallSteps.p1,
      keyVideo: 'lIfXayDRoVE',
      link: '',
    },
    {
      title: proyectsText.bikey.company,
      text: proyectsText.bikey.descriptionOne,
      subtitle: proyectsText.typesProyects.app,
      textVideo: proyectsText.bikey.descriptionTwo,
      image: bikey,
      setShowModalVideo,
      textButtonAction: proyectsText.download,
      titleProduct: proyectsText.bikey.p1,
      keyVideo: 'qlQM9bc4RAA',
      link: 'https://play.google.com/store/apps/details?id=com.Bikey&hl=en',
    },
    {
      title: proyectsText.npmProyectPdf.company,
      text: proyectsText.npmProyectPdf.description,
      subtitle: proyectsText.typesProyects.webApp,
      textVideo: proyectsText.npmProyectPdf.description,
      image: reactPdf,
      setShowModalVideo,
      textButtonAction: proyectsText.download,
      titleProduct: proyectsText.npmProyectPdf.p1,
      keyVideo: 'JRvhTebJLQ4',
      link: 'https://www.npmjs.com/package/react-pdf-simulator',
    },
    {
      title: proyectsText.suraProyect.company,
      text: proyectsText.suraProyect.description,
      subtitle: proyectsText.typesProyects.webApp,
      textVideo: proyectsText.suraProyect.description,
      image: suraApp,
      setShowModalVideo,
      textButtonAction: '',
      titleProduct: proyectsText.suraProyect.p1,
      keyVideo: 'r4JU7pu2ReI',
      link: '',
    },
    {
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      subtitle: proyectsText.typesProyects.webApp,
      textVideo: proyectsText.unalProyect.description,
      image: chaea,
      setShowModalVideo,
      textButtonAction: proyectsText.see,
      titleProduct: proyectsText.unalProyect.p1,
      keyVideo: '5mHVtn6eY6A',
      link: 'http://gaia.manizales.unal.edu.co/chaea/logingIndex.php',
    },
    {
      image: redTrofica,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'Trophic Level',
      keyVideo: 'SflwWWucJT4',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/78/',
    },
    {
      image: biomas,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'Biomes',
      keyVideo: 'ZRgXH51XsTQ',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/268/',
    },
    {
      image: problemasAmbientales,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'Environmental problems',
      keyVideo: 'D1rTNy6xT9k',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/86/',
    },
    {
      image: energiaAlternativa,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'Alternative Energy',
      keyVideo: 'dFXBpie3Ojo',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/154/',
    },
    {
      image: cicloDelAgua,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'Water Cycle',
      keyVideo: 'C7VGujyCM3c',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/223/',
    },
    {
      image: revolucionIndustrial,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'Industrial Revolution',
      keyVideo: 'IKuO6S3qYBU',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/171/',
    },
    {
      image: eraNapoleonica,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'The Napoleonic',
      keyVideo: 'tBoVDeZNFU4',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/181/',
    },
    {
      image: regionPacifico,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'Pacific Region',
      keyVideo: 'qcfDTNTVlPg',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/232/',
    },
    {
      image: fotosintesis,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'Photosynthesis',
      keyVideo: '6dxi86o8KcE',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/225/',
    },
    {
      image: estadosMateria,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: "Material's States",
      keyVideo: 'jznU1q2Y_N8',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/227/',
    },
    {
      image: regionOrinoquia,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'Orinoquía Region',
      keyVideo: '3CIOmT9_g94',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/231/',
    },
    {
      image: andina,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'Andean Region',
      keyVideo: 'JJbY6Ag5iW8',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/226/',
    },
    {
      image: edadMedia,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'Middle Ages',
      keyVideo: 'YjKrJCj3LV4',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/228/',
    },
    {
      image: regionInsular,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'Island Region',
      keyVideo: 'p7HpGueqTJY',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://froac.manizales.unal.edu.co/roapRAIM/scorm/288/',
    },
    {
      image: meiosis,
      title: proyectsText.unalProyect.company,
      text: proyectsText.unalProyect.description,
      setShowModalVideo,
      subtitle: proyectsText.typesProyects.ARapp,
      titleProduct: 'Cell Division (Meiosis)',
      keyVideo: 'wBHCOtyV2eQ',
      textVideo: proyectsText.unalProyect.description,
      textButtonAction: proyectsText.download,
      link: 'http://gaia.manizales.unal.edu.co/chaea/logingIndex.php',
    },
  ];
};
