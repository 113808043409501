import React, { useReducer, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import actions from './actions';
const initalState = {
  language: '',
};

const reducer = (storage, action) => {
  switch (action.type) {
    case actions.CHANGE_LANGUAGE: {
      return {
        ...storage,
        ...action,
      };
    }
    default: {
      return storage;
    }
  }
};
const StorageContext = createContext();

const Storage = (props) => {
  const { children } = props;
  const [storage, dispatch] = useReducer(reducer, initalState);
  console.log('storage>>', { storage });
  return (
    <StorageContext.Provider value={{ storage, dispatch }}>
      {children}
    </StorageContext.Provider>
  );
};
export const useStorage = () => useContext(StorageContext);
export default Storage;

Storage.propTypes = {
  children: PropTypes.node,
};
