import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../../../utils/Colors';
export default makeStyles((theme) => ({
  titleEducation: {
    textAlign: 'center',
    fontFamily: 'Oranienbaum',
    color: ' rgb(84, 74, 43)',
  },
  containerEducation: {
    padding: '2rem',
    backgroundImage: `linear-gradient( to right,rgb(169,141,58),  #F4DE6E, rgb(169,141,58))`,
  },
  divider: {
    border: 0,
    height: '3px',
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0), rgb(84, 74, 43), rgba(0, 0, 0, 0))`,
  },
  containerCard: {
    textAlign: 'center',
    backgroundColor: Colors.balckTwo,
    color: Colors.blueOne,
    padding: '4%',
    marginTop: '3%',
    backgroundImage: `linear-gradient( to right,rgb(169,141,58),  #F4DE6E, rgb(169,141,58))`,
    marginBottom: '2%',
  },
  imageCompany: {
    height: '2px',
    margin: '1%',
    textAlign: 'center',
  },
}));
