import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../../../utils/Colors';
export default makeStyles((theme) => ({
  cardCompany: {
    textAlign: 'center',
    backgroundColor: '#ffff',
    color: '#ffff',
    padding: '4%',
    marginTop: '3%',
    backgroundImage: `linear-gradient( to right,rgb(9,58,71), rgb(16,128,149),rgb(9,58,71))`,
    marginBottom: '2%',
    minHeight: '15vh',
    maxHeight: '15vh',
  },
  divider: {
    border: 0,
    height: '3px',
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0),${Colors.grayOne}, rgba(0, 0, 0, 0))`,
  },
  row1: {
    backgroundColor: 'rgb(9,58,71)',
  },
  row2: {
    backgroundColor: 'rgb(9,58,71)',
    minHeight: '30vh',
  },
  imageCompani: {
    color: '#fff',
    textAlign: 'center',
    alignItems: 'center',
  },
  profileImage: {
    width: '55%',
  },
}));
