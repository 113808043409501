import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { get } from 'lodash';
import { Close } from '@material-ui/icons';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const ModalVideo = (props) => {
  const [showSpinner, setShowSpinner] = useState(true);
  const {
    showModalVideo: { open, position },
    setShowModalVideo,
    products,
  } = props;

  const textVideo = get(products[position], 'textVideo');
  const title = get(products[position], 'title');
  const subtitle = get(products[position], 'subtitle');
  const titleProduct = get(products[position], 'titleProduct');
  const keyVideo = get(products[position], 'keyVideo');

  return (
    <div>
      <Dialog
        open={open}
        onClick={() => setShowModalVideo(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogActions>
          <Button
            onClick={() => setShowModalVideo(false)}
            color="primary"
            autoFocus
          >
            <Close />
          </Button>
        </DialogActions>
        <DialogTitle id="alert-dialog-title">
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography gutterBottom variant="subtitle1" component="h6">
            {titleProduct}
          </Typography>
          <Typography gutterBottom variant="subtitle2" component="h6">
            {subtitle}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {showSpinner && (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
              }}
            >
              <CircularProgress />
            </div>
          )}
          <ReactPlayer
            onReady={() => setShowSpinner(false)}
            playing={true}
            controls={true}
            muted={true}
            url={`https://www.youtube.com/watch?v=${keyVideo}`}
            width={'100%'}
            onEnded={() => setShowModalVideo(false)}
          />
          <br />
          <DialogContentText id="alert-dialog-description">
            {textVideo || ''}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
ModalVideo.defaultProps = {
  products: [
    {
      keyVideo: 'SflwWWucJT4',
      textVideo: '',
    },
  ],
  showModalVideo: {
    position: 0,
    open: false,
  },
};
ModalVideo.propTypes = {
  open: PropTypes.bool,
  products: PropTypes.array,
  keyVideo: PropTypes.string,
  textVideo: PropTypes.string,
  setShowModalVideo: PropTypes.func,
  showModalVideo: PropTypes.object,
};

export default ModalVideo;
