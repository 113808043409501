import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { map } from 'lodash';
import PropTypes from 'prop-types';
const profileImagen = require('../../../../utils/assets/johan2.png');

const CardText = (props) => {
  const { title = '', subTitle = '', paragraphs = {} } = props;
  const classes = useStyles();
  return (
    <>
      <div className={classes.cardProfileImage}>
        <img
          src={profileImagen}
          alt="profile_image"
          className={classes.profileImage}
        />
      </div>
      <Typography variant="h3" component="h2" className={classes.title}>
        {title}
      </Typography>
      <hr className={classes.divider} />
      <Typography
        variant="h4"
        component="h2"
        className={classes.title}
        gutterBottom={true}
      >
        {subTitle}
      </Typography>
      <br />
      {map(paragraphs, (paragraph, key) => (
        <div key={key + 'texts'}>
          <Typography
            variant="body1"
            component="p"
            align="center"
            className={classes.paragraf}
          >
            {paragraph}
          </Typography>
          <br />
        </div>
      ))}
    </>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    fontFamily: 'Oranienbaum',
  },
  divider: {
    border: 0,
    height: '3px',
    backgroundImage:
      'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(140, 140, 140, 0.7), rgba(0, 0, 0, 0))',
  },
  paragraf: {
    fontFamily: 'Open Sans',
    fontSize: '18px',
  },
  profileImage: {
    borderRadius: '100%',
    width: '25%',
    webkitBoxShadow: 'none',
    boxShadow: 'none',
    overflow: 'hidden',
  },
}));

export default CardText;

CardText.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  paragraphs: PropTypes.object,
};
