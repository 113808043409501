import { changeLanguageAct } from './reducer/actionsCreators';

export const changeLanguage = (elements) => {
  const { dispatch, language } = elements;
  changeLanguageAct({ dispatch, language });
};

export function redirect(href) {
  if (window) window.location.href = href;
  return true;
}
