import Colors from '../../../../utils/Colors';

const configureSkills = ({ skills }) => {
  const labels = [];
  const data = [];
  skills.map((val) => {
    labels.push(val.skill);
    data.push(val.level);
    return '';
  });
  return { labels, data };
};

export const data = (elements) => {
  const { canvasRef, skills, mySkills } = elements;
  const { labels, data } = configureSkills({ skills });
  const canvas = canvasRef.chartInstance.canvas;

  const ctx = canvasRef.chartInstance.ctx;
  const midleCanvasWidth = canvas.width / 2 - 130;
  const midleCanvasHeight = midleCanvasWidth / 2 - 80;
  const gradient = ctx.createRadialGradient(
    midleCanvasWidth,
    midleCanvasHeight,
    10,
    midleCanvasWidth,
    midleCanvasHeight,
    200,
  );
  //  gradient.addColorStop(0, "red");
  //  gradient.addColorStop(0.3, "blue");
  gradient.addColorStop(0, Colors.goldenFour);
  gradient.addColorStop(0.2, Colors.goldenThree);
  gradient.addColorStop(1, Colors.goldenOne);
  return {
    labels,
    datasets: [
      {
        label: mySkills,
        backgroundColor: gradient,
        borderColor: Colors.goldenThree,
        pointBackgroundColor: Colors.goldenThree,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: Colors.goldenThree,
        data,
      },
    ],
  };
};

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  scale: {
    gridLines: {
      color: Colors.goldenTwo,
      drawBorder: true,
      zeroLineColor: Colors.goldenTwo,
    },
    angleLines: {
      color: Colors.goldenOne,
    },
    pointLabels: {
      fontColor: '#fff',
    },
    // En este punto se pone los cuadros numero del redar
    ticks: {
      display: true,
      max: 5,
      min: 0,
      stepSize: 1,
      callback: (value, index, values) => {
        const result = (value * 100) / (values.length - 1);
        return `${result}%`;
      },
    },
  },
  tooltips: {
    callbacks: {
      title: function (tooltipItem, data) {
        const value = ((tooltipItem[0].value * 100) / 5).toFixed();
        return `${data['labels'][tooltipItem[0]['index']]}: ${value}%`;
      },
      label: () => '',
    },
    backgroundColor: Colors.balckTwo,
    titleFontColor: '#fff',
    titleFontSize: 40,
    titleSpacing: 20,
    bodyFontColor: '#fff',
    bodyFontSize: 14,
    displayColors: false,
    titleFontStyle: 'Oranienbaum',
  },
  legend: {
    display: true,
    labels: {
      fontColor: '#ffff',
      fontFamily: 'Consolas',
      fontSize: 15,
    },
    position: 'bottom',
  },
  plugins: {
    datalabels: {
      color: Colors.goldenOne,
      formatter: () => '',
    },
  },
};
