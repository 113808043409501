import React, { useEffect, useState } from 'react';
import { isEmpty, get } from 'lodash';
import { Typography } from '@material-ui/core';
import { Radar } from 'react-chartjs-2';
import { Col } from 'react-flexbox-grid';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { skillsThecnical } from './data';
import { data, options } from './funtionsSkillsSpider';

const ProcessSkills = (props) => {
  const { dimensions, codeSkills, words } = props;
  const [canvasRef, setCanvasRef] = useState();
  const [dataChart, setDataChart] = useState([]);

  const [title, setTitle] = useState('');
  const classes = useStyles();
  const skillsManagement = get(words, 'skills.skillsManagement');
  const skillsSocial = get(words, 'skills.skillsSocial');
  const otherSkills = get(words, 'skills.otherSkills');
  const mySkills = get(words, 'skills.mySkills');

  useEffect(() => {
    if (!isEmpty(canvasRef)) {
      switch (codeSkills) {
        case 'management': {
          setDataChart(data({ canvasRef, skills: skillsManagement, mySkills }));
          const managementSkill = get(words, 'skills.managementSkill');
          setTitle(managementSkill);
          break;
        }
        case 'thecnical': {
          setDataChart(data({ canvasRef, skills: skillsThecnical, mySkills }));
          setTitle(get(words, 'skills.thecnicalSkill'));
          break;
        }
        case 'social': {
          setDataChart(data({ canvasRef, skills: skillsSocial, mySkills }));
          setTitle(get(words, 'skills.socialSkill'));
          break;
        }
        case 'otherSkills': {
          setDataChart(data({ canvasRef, skills: otherSkills, mySkills }));
          setTitle(get(words, 'skills.otherSkill'));
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [
    canvasRef,
    dimensions,
    codeSkills,
    mySkills,
    skillsManagement,
    skillsSocial,
    words,
    otherSkills,
  ]);
  return (
    <>
      <Col lg={12}>
        <Typography variant="h3" component="h2" className={classes.title}>
          {title}
        </Typography>
        <hr className={classes.divider} />
      </Col>
      <Col lg={12} className={classes.colChartSkill}>
        {/* Have error but I don´t know what is the error from data */}
        <Radar
          data={dataChart}
          options={options}
          ref={(refe) => {
            setCanvasRef(refe);
          }}
        />
      </Col>
    </>
  );
};

const useStyles = makeStyles(() => ({
  colChartSkill: {
    marginTop: '2%',
  },
  title: {
    fontFamily: 'Oranienbaum',
  },
  divider: {
    border: 0,
    height: '3px',
    backgroundImage:
      'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(140, 140, 140, 0.7), rgba(0, 0, 0, 0))',
  },
}));

export default ProcessSkills;
ProcessSkills.propTypes = {
  dimensions: PropTypes.object,
  words: PropTypes.object,
  codeSkills: PropTypes.string,
};
