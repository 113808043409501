import React, { useState, useEffect } from 'react';
import { Row } from 'react-flexbox-grid';
import Colors from '../../../../utils/Colors';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import MenuButton from './MenuButton';
import ProcessSkills from './ProcessSkills';
import useWords from '../../../../utils/Words';
import useWindowDimensions from '../../../../utils/Dimensions';

const SkillsSpider = () => {
  const dimensions = useWindowDimensions();
  const words = useWords();
  const [codeSkills, setCodeSkills] = useState('management');
  const classes = useStyles();
  useEffect(() => {}, [words]);
  return (
    <Row className={classes.row1} middle={'xs'} center={'xs'} id={'2Skills'}>
      <Card className={classes.cardCompany}>
        <MenuButton
          setCodeSkills={setCodeSkills}
          codeSkills={codeSkills}
          words={words}
        />
        <ProcessSkills
          dimensions={dimensions}
          codeSkills={codeSkills}
          words={words}
        />
      </Card>
    </Row>
  );
};

const useStyles = makeStyles(() => ({
  row1: {
    backgroundColor: Colors.charcoal,
    minHeight: '100vh',
  },
  cardCompany: {
    textAlign: 'center',
    backgroundColor: Colors.charcoalDarck,
    color: '#fff',
    width: '70%',
    padding: '2%',
  },
}));

export default SkillsSpider;
