import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../../../utils/Colors';
export default makeStyles((theme) => ({
  buttonGoldenColor: {
    color: '#fff',
    background: `linear-gradient(45deg, rgb(169,141,58) 30%,  #F4DE6E 90%) `,
    boxShadow: `0 3px 5px 2px ${Colors.goldenThreeD}`,
  },
  row1: {
    backgroundColor: Colors.charcoal,
    minHeight: '100vh',
  },
  cardCompany: {
    textAlign: 'center',
    backgroundColor: Colors.charcoalDarck,
    color: '#fff',
    width: '70%',
    padding: '2%',
    marginTop: 30,
    marginBottom: 30,
  },
  downIcon: {
    color: '#fff',
    fontSize: 40,
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Oranienbaum',
  },
  divider: {
    border: 0,
    height: '3px',
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0),${Colors.grayOne}, rgba(0, 0, 0, 0))`,
    marginBottom: '3%',
  },
}));
