import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Card, Fab, Typography } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ProductCard from './ProductCard';
import ModalVideo from '../MosalVideo/ModalVideo';

import useWords from '../../../../utils/Words';
import { productsf } from './data';
import Classes from './Sytiles';
const SkillsSpider = () => {
  const [showModalVideo, setShowModalVideo] = useState({
    open: false,
    position: 0,
  });
  const [countShow, setCountShow] = useState(3);
  const words = useWords();
  const { proyectsText } = words;
  const classes = Classes();
  useEffect(() => {}, [words]);
  const products = productsf({ setShowModalVideo, proyectsText });

  return (
    <>
      <ModalVideo
        showModalVideo={showModalVideo}
        setShowModalVideo={setShowModalVideo}
        products={products}
      />
      <Row
        className={classes.row1}
        middle={'xs'}
        center={'xs'}
        id={'4Proyects'}
      >
        <Card className={classes.cardCompany}>
          <Col lg={12}>
            <Typography variant="h3" component="h2" className={classes.title}>
              {proyectsText.proyects}
            </Typography>
            <hr className={classes.divider} />
          </Col>
          <Row>
            {products.map((product, key) => {
              if (key <= countShow - 1) {
                return (
                  <Col xs={12} md={6} lg={4} key={key + '-proyects'}>
                    <ProductCard
                      {...product}
                      position={key}
                      proyectsText={proyectsText}
                    />
                  </Col>
                );
              }
              return null;
            })}
          </Row>
          <Row middle={'xs'} center={'xs'}>
            {countShow !== products.length && (
              <Fab
                label="Primary"
                color="secondary"
                className={classes.buttonGoldenColor}
                onClick={() => {
                  const ifLoading = countShow + 3 <= products.length;
                  ifLoading
                    ? setCountShow(countShow + 3)
                    : setCountShow(products.length);
                }}
              >
                <ArrowDropDown className={classes.downIcon} />
              </Fab>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default SkillsSpider;
