//theme configure https://medium.com/heuristics/react-dark-mode-switch-in-material-ui-dashboard-82fcf1cded66
import React, { useState } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Switch } from 'react-router-dom';
import { lightBlue, deepPurple, brown, yellow } from '@material-ui/core/colors';
import Routes from './components/presentational/commons/Routes';
import Storage from './storage/index';
import './App.css';

function App() {
  // eslint-disable-next-line no-unused-vars
  const [darkState, setDarkState] = useState(true);
  const palletType = darkState ? 'dark' : 'light';
  const mainPrimaryColor = darkState ? yellow[200] : lightBlue[500];
  const mainSecondaryColor = darkState ? brown['400'] : deepPurple[500];
  const darkTheme = createMuiTheme({
    palette: {
      type: palletType,
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
    },
  });
  return (
    <ThemeProvider theme={darkTheme}>
      <Storage>
        <BrowserRouter basename="/JNC">
          <Switch>
            <Routes />
          </Switch>
        </BrowserRouter>
      </Storage>
    </ThemeProvider>
  );
}

export default App;
